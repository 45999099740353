import * as Sentry from '@sentry/sveltekit';

import { envString, isDev } from './utils/envs';

Sentry.init({
  dsn: 'https://9e12060cb8871490ba8aac9f7347a147@sentry.kelp.digital/9',
  release: import.meta.env.VITE_SENTRY_RELEASE_VERSION,
  debug: isDev(),
  enabled: !isDev(),
  replaysOnErrorSampleRate: 1,
  environment: envString(),
  tracesSampleRate: 1,
  sampleRate: 1,
  replaysSessionSampleRate: 1,
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration()
  ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
